import React from 'react';
import Button from '../components/ui/Button';
import CircleNumber from '../components/CircleNumber';
// import CustomerCard from '../components/CustomerCard';
import HowItWorksCard from '../components/HowItWorksCard';
import ContactForm from '../components/ContactForm';
// import LabelText from '../components/LabelText';
import Layout from '../components/layout/Layout';
import SplitSection from '../components/SplitSection';
// import StatsBox from '../components/StatsBox';
import Signup from '../components/Signup';
import customerData from '../data/customer-data';
import HeroImage from '../svg/Hero';
import HomeScreen from '../svg/HomeScreen';
import GooglePlay from '../svg/GooglePlay';
import AppStore from '../svg/AppStore';
import ContainerImage from '../svg/Container';
import ScanImage from '../svg/Scan';
import ReturnImage from '../svg/Return';
import {css} from 'styled-components';
import {Link} from 'gatsby';
import Container from '../../static/container_hand.png';
// import Carousel from '../components/Carousel';
// import partnerImages from '../components/carousel-images/PartnerImages';
// import SvgCharts from '../svg/SvgCharts';

const PickupText = 'Meal is served in a Foodware box';
const ScanText =
  'Download the app to scan the QR code & enjoy your meal waste-free!';
const ReturnText =
  'Return Foodware container to the original location or other Foodware Hub';

const Index = () => (
  <Layout>
    <section className="py-20 md:pt-40">
      <div className="container mx-auto px-8 lg:flex">
        <div className="lg:w-1/2">
          <div className= "text-center lg:text-left">
            <h1 className="text-5xl xl:text-6xl text-green leading-none font-header">
              Takeout made sustainable
            </h1>
            <p className="text-xl font-body lg:text-xl mt-6 font-light">
              Replacing single-use takeout containers by introducing a collect &
              return system that is convenient, cost-efficient, and
              environmentally friendly.
            </p>
          </div>
          {/* <p className="mt-4 text-gray-600">Sed fermentum felis ut cursu</p> */}
          <Signup />
        </div>
        <div className="lg:w-1/2 pt-20 lg:pt-0 flex justify-center">
          <HeroImage />
        </div>
      </div>
    </section>
    <SplitSection
      id="whatwedo"
      className="bg-gold pt-20"
      primarySlot={
        <div className="lg:pt-4 lg:pl-20 xl:pl-40 text-center lg:text-left">
          <h3 className="text-3xl lg:text-5xl font-header leading-tight">
            What We Do
          </h3>
          <p className="mt-8 text-xl lg:text-2xl font-body leading-relaxed">
            We replace single-use takeout containers with a collect and return
            system and reward you for doing so!
          </p>
        </div>
      }
      secondarySlot={<img src={Container} />}
    />
    {/*<section id="features" className="bg-white pt-20 pb-24 md:pb-32">
      <div className="container mx-auto text-center">
        <h2 className="text-3xl lg:text-5xl font-header">Trusted by</h2>
        <Carousel images={partnerImages}/>
      </div>
    </section>*/}
    <section id="features" className="py-20 lg:pb-40">
      <div className="container mx-auto text-center">
        <h2 className="text-3xl lg:text-5xl font-header">How It Works</h2>
        <div className="flex flex-col lg:flex-row sm:-mx-3 xl:mx-6 mt-12">
          <div className="flex-1 px-3 font-body">
            <CircleNumber className="bg-green transform translate-y-8 text-white font-bold text-2xl">
              1
            </CircleNumber>
            <HowItWorksCard
              title={'Pick Up'}
              content={PickupText}
              svg={<ContainerImage />}
            />
          </div>
          <div className="flex-1 px-3 font-body">
            <CircleNumber className="bg-green transform translate-y-8 text-white font-bold text-2xl">
              2
            </CircleNumber>
            <HowItWorksCard
              title={'Scan'}
              content={ScanText}
              svg={<ScanImage />}
            />
          </div>
          <div className="flex-1 px-3 font-body">
            <CircleNumber className="bg-green transform translate-y-8 text-white font-bold text-2xl">
              3
            </CircleNumber>
            <HowItWorksCard
              title={'Return'}
              content={ReturnText}
              svg={<ReturnImage />}
            />
          </div>
        </div>
      </div>
    </section>
    <section id="mobile" className="py-20 lg:pb-0 bg-gold sm:justify-center">
      <div className="container mx-auto px-12 sm:px-32 lg:flex">
        <div className="text-center lg:text-left lg:pt-4 lg:pl-20 xl:pl-32">
          <div className="text-3xl lg:text-5xl leading-tight font-header">
            Start making a{' '}
            <span
              className="w-1/8"
              css={css`
                text-decoration: underline;
                text-decoration-thickness: 10px;
                text-decoration-color: #a8e8b5;
              `}
            >
              difference
            </span>
            {' '}& enjoy a guilt-free takeout experience
          </div>

          <p className="text-2xl lg:text-3xl font-body mt-12 justify-center whitespace-no-wrap">
            Download the app today
          </p>
          <div className="flex flex-row mt-12 justify-center lg:justify-start">
            <div className="pr-5 sm:pr-12">
              <Link to="https://play.google.com/store/apps/details?id=com.foodware.mobile">
                <GooglePlay className="w-40 sm:w-auto" />
              </Link>
            </div>
            <div>
              <Link to="https://apps.apple.com/app/foodware-zero-waste-takeout/id1592974816">
                <AppStore className="w-40 sm:w-auto" />
              </Link>
            </div>
          </div>
        </div>
        <div className="invisible hidden lg:visible lg:w-80 lg:pr-20 lg:flex lg:justify-left">
          <HomeScreen />
        </div>
      </div>
    </section>
    {/*<div className="py-10 px-10">
      <ContactForm />
    </div>*/}
    {/*<SplitSection
      reverseOrder
      primarySlot={
        <div className="lg:pl-32 xl:pl-48">
          <h3 className="text-3xl font-semibold leading-tight">
            Design And Plan Your Business Growth Steps
          </h3>
          <p className="mt-8 text-xl font-light leading-relaxed">
            Once the market analysis process is completed our staff will search for opportunities
            that are in reach
          </p>
        </div>
      }
      secondarySlot={<SvgCharts />}
    />*/}
    {/*<section id="stats" className="py-20 lg:pt-32">
      <div className="container mx-auto text-center">
        <LabelText className="text-gray-600">Our customers get results</LabelText>
        <div className="flex flex-col sm:flex-row mt-8 lg:px-24">
          <div className="w-full sm:w-1/3">
            <StatsBox primaryText="+100%" secondaryText="Stats Information" />
          </div>
          <div className="w-full sm:w-1/3">
            <StatsBox primaryText="+100%" secondaryText="Stats Information" />
          </div>
          <div className="w-full sm:w-1/3">
            <StatsBox primaryText="+100%" secondaryText="Stats Information" />
          </div>
        </div>
      </div>
    </section>*/}
    {/*<section id="testimonials" className="py-20 lg:py-40">
      <div className="container mx-auto">
        <LabelText className="mb-8 text-gray-600 text-center">What customers are saying</LabelText>
        <div className="flex flex-col md:flex-row md:-mx-3">
          {customerData.map(customer => (
            <div key={customer.customerName} className="flex-1 px-3">
              <CustomerCard customer={customer} />
            </div>
          ))}
        </div>
      </div>
    </section>*/}
    {/*<section className="container mx-auto my-20 py-24 bg-gray-200 rounded-lg text-center">
      <h3 className="text-5xl font-semibold">Ready to grow your business?</h3>
      <p className="mt-8 text-xl font-light">
        Quis lectus nulla at volutpat diam ut. Enim lobortis scelerisque fermentum dui faucibus in.
      </p>
      <p className="mt-8">
        <Button size="xl">Get Started Now</Button>
      </p>
    </section>*/}
  </Layout>
);

export default Index;
