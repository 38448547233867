import React from 'react';
import Card from './ui/Card';

const HowItWorksCard = (props) => (
  <Card className="lg:pb-0 pt-16 lg:mx-6 xl:mx-12 mb-8 lg:mb-0 h-full">
    <p className="font-bold text-xl">{props.title}</p>
    <div className="flex justify-center py-10">{props.svg}</div>
    <p>{props.content}</p>
  </Card>
);

export default HowItWorksCard;
