import React from "react";

const Scan = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="58"
    height="58"
    fill="none"
    viewBox="0 0 150 150"
  >
    <path
      fill="#000"
      d="M117.857 139.286v-10.715h10.714v10.715h-10.714zM85.714 117.857v-10.714H96.43v10.714H85.714zM85.714 150h21.429v-10.714H96.429v-10.715H85.714V150zM128.572 128.571v-21.428h10.714v21.428h-10.714zM139.286 128.571H150V150h-21.428v-10.714h10.714v-10.715zM128.572 96.428V85.714H150v21.428h-10.714V96.428h-10.714z"
    ></path>
    <path
      fill="#000"
      d="M117.857 96.429h-10.714v21.428H96.428v10.715h21.429V96.429zM85.714 96.428V85.714h21.429v10.714H85.714zM21.428 107.143h21.429v21.428H21.429v-21.428z"
    ></path>
    <path
      fill="#000"
      d="M64.286 150H0V85.714h64.286V150zm-53.572-10.715h42.857V96.428H10.714v42.857zM107.143 21.429h21.428v21.428h-21.428V21.43z"
    ></path>
    <path
      fill="#000"
      d="M150 64.286H85.714V0H150v64.286zM96.429 53.57h42.857V10.714H96.429v42.857zM21.428 21.429h21.429v21.428H21.429V21.43z"
    ></path>
    <path
      fill="#000"
      d="M64.286 64.286H0V0h64.286v64.286zM10.714 53.57h42.857V10.714H10.714v42.857z"
    ></path>
  </svg>
);

export default Scan;
